import httpClient from '@/utilities/http/http-client'

const endpoint = 'api/v1/coa'

export default {
  get (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}${queryParams}`)
  },

  create (params) {
    return httpClient.post(`${endpoint}`, params)
  },

  update (params) {
    return httpClient.patch(`${endpoint}`, params)
  },

  delete (id) {
    return httpClient.delete(`${endpoint}/${id}`)
  },

  getAllByProyek (idProyek) {
    return httpClient.get(`${endpoint}/allByProyek/${idProyek}`)
  },

  saveD (params) {
    return httpClient.post(`${endpoint}/saveD`, params)
  },

  all (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/all${queryParams}`)
  },

  getD (params = null) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/getD${queryParams}`)
  },

  checkUsed (params) {
    const queryParams = params ? `?${new URLSearchParams(params).toString()}` : ''
    return httpClient.get(`${endpoint}/checkUsed${queryParams}`)
  }
}
