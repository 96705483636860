<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Batalkan Kontrak" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">No Kontrak</label>
            <vs-input class="w-full" :value="storeKontrakDetail.kontrak.no_kontrak" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total Nilai</label>
            <v-money class="w-full" :value="storeKontrakDetail.kontrak.grand_total" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total Bayar</label>
            <v-money class="w-full" :value="storeKontrakDetail.kontrak.total_bayar" disabled/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Total Sisa</label>
            <v-money class="w-full" :value="storeKontrakDetail.kontrak.total_sisa" disabled/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Tgl Batal *</label>
            <flat-pickr class="w-full" v-model="data.tgl_batal"></flat-pickr>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Alasan Batal *</label>
            <vs-select v-model="data.alasan_batal" class="w-full">
              <vs-select-item v-for="(it, index) in listAlasanBatal" :key="index" :value="it" :text="it"/>
            </vs-select>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Keterangan Batal *</label>
            <vs-textarea class="w-full" v-model="data.keterangan_batal"/>
          </div>
        </div>

        <vs-divider>Journals</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="flex flex-wrap justify-between items-center mb-1">
              <div class="mb-4 md:mb-0 mr-4">
                <div class="flex items-center space-x-2">
                  <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3">Add</vs-button>
                  <vs-button color="primary" type="flat" icon-pack="feather" @click="showModalPaymentHistory" class="py-2 px-3">Show Payment History</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <vs-table :data="data.journals" stripe>
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">COA Debet *</vs-th>
            <vs-th class="whitespace-no-wrap">COA Kredit *</vs-th>
            <vs-th class="whitespace-no-wrap">Description *</vs-th>
            <vs-th class="whitespace-no-wrap">Total *</vs-th>
            <vs-th class="whitespace-no-wrap">Refund</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>
                <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
              </vs-td>
              <vs-td>
                  <div class="w-64">
                    <div @click="chooseCoa(item.uuid, 'DEBET')">
                      <vx-input-group>
                        <vs-input placeholder="COA Debet" :value="item.nama_coa_debet" readonly/>
                        <template slot="append">
                          <div class="append-text btn-addon">
                            <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                          </div>
                        </template>
                      </vx-input-group>
                    </div>
                  </div>
                </vs-td>
              <vs-td>
                  <div class="w-64">
                    <div @click="chooseCoa(item.uuid, 'KREDIT')">
                      <vx-input-group>
                        <vs-input placeholder="COA Kredit" :value="item.nama_coa_kredit" readonly/>
                        <template slot="append">
                          <div class="append-text btn-addon">
                            <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                          </div>
                        </template>
                      </vx-input-group>
                    </div>
                  </div>
                </vs-td>
              <vs-td><vs-input class="w-52" type="text" v-model="item.keterangan"/></vs-td>
              <vs-td><v-money class="w-48 text-right" type="text" v-model="item.jml_total"/></vs-td>
              <vs-td><vs-checkbox size="small" v-model="item.isRefund"></vs-checkbox></vs-td>
            </vs-tr>

            <!--footer-->
            <vs-tr v-if="data.length > 0">
              <vs-td class="text-right"></vs-td>
              <vs-td class="text-right"></vs-td>
              <vs-td class="text-right"></vs-td>
              <vs-td class="text-right"></vs-td>
              <vs-td class="text-left">{{ summary.grandTotal | idr }}</vs-td>
              <vs-td class="text-right"></vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih COA"
                :active="modalCoa.active"
                v-on:update:active="modalCoa.active = $event">
        <Coa :selectable="true"
             :externalFilter="filterCoa"
             @selected="onSelectedModalCoa"/>
      </vs-popup>

      <KontrakPaymentHistory :isActive.sync="modalPaymentHistory.active"
                             :idKontrak="modalPaymentHistory.idKontrak"/>

    </vs-popup>
  </div>
</template>

<script>
import KontrakRepository from '@/repositories/marketing/kontrak/kontrak-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import moment from 'moment'
import { convertToFormData } from '@/utilities/common/global-methods'
import VMoney from '@/views/components/v-money/VMoney'
import Coa from '@/views/pages/master/coa/Coa.vue'
import { v4 as uuid } from 'uuid'
import KontrakPaymentHistory from '@/views/pages/marketing/kontrak/KontrakPaymentHistory.vue'

export default {
  name: 'KontrakBatal',
  props: ['isActive', 'idKontrak'],
  components: {
    KontrakPaymentHistory,
    ValidationErrors,
    flatPickr,
    Coa,
    VMoney
  },
  computed: {
    storeKontrakDetail () {
      return this.$store.state.marketing.kontrakDetail
    },
    kontrak: {
      get () {
        return this.storeKontrakDetail.kontrak
      },
      set (value) {
        this.$store.commit('marketing/kontrakDetail/SET_KONTRAK', value)
      }
    },
    summary () {
      return {
        grandTotal: _.sumBy(this.data.journals, item => parseFloat(item.jml_total || '0'))
      }
    },
    filterCoa () {
      return {
        tipe: 'DETAIL',
        id_proyek: this.kontrak.id_proyek
      }
    }
  },
  data () {
    return {
      isLoading: false,
      errors: null,
      listAlasanBatal: ['PINDAH UNIT', 'LAINNYA'],
      modalCoa: {
        rowUuid: null,
        type: null,
        active: false
      },
      modalPaymentHistory: {
        idKontrak: null,
        active: false
      },
      data: {
        tgl_batal: moment().format('YYYY-MM-DD'),
        journals: []
      }
    }
  },
  methods: {
    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({
        ...this.data,
        id_kontrak: this.$route.params.idKontrak
      })

      KontrakRepository.batalKontrak(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    chooseCoa (rowUuid, type) {
      this.modalCoa.rowUuid = rowUuid
      this.modalCoa.type = type
      this.modalCoa.active = true
    },

    showModalPaymentHistory () {
      this.modalPaymentHistory.idKontrak = this.$route.params.idKontrak
      this.modalPaymentHistory.active = true
    },

    onSelectedModalCoa (data) {
      const uuid = this.modalCoa.rowUuid
      const index = _.findIndex(this.data.journals, item => item.uuid === uuid)
      if (this.modalCoa.type === 'DEBET') {
        this.data.journals[index].id_coa_debet = data.id
        this.data.journals[index].nama_coa_debet = data.kode + ' - ' + data.nama
      } else {
        this.data.journals[index].id_coa_kredit = data.id
        this.data.journals[index].nama_coa_kredit = data.kode + ' - ' + data.nama
      }
      this.modalCoa.active = false
    },

    addRow () {
      const row = {
        uuid: uuid(),
        id_coa_debet: null,
        id_coa_kredit: null,
        jml_total: 0,
        keterangan: null,
        isRefund: false
      }
      this.data.journals.push(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.data.journals, item => item.uuid === uuid)
      this.data.journals.splice(index, 1)
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
